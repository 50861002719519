<template>
  <footer class="app-footer">
    <div class="container">
      <section class="top">
        <!-- 1 col -->
        <div class="col--first">
          <img
            src="@/assets/images/truth-to-justice-logo--white.svg"
            width="154"
            alt="Logo"
            class="ttj-logo"
          />
          <p class="label">{{ $t("common.createdWhy") }}</p>
          <ul class="socials">
            <li>
              <a
                href="https://www.instagram.com/vitsche_berlin"
                target="_blank"
                title="Instagram"
              >
                <img
                  src="@/assets/images/socials/instagram.svg"
                  alt="Instagram"
                  width="24px"
                  height="24px"
                />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/VitscheBerlin"
                target="_blank"
                title="Twitter"
                width="24px"
                height="24px"
              >
                <img
                  src="@/assets/images/socials/x-twitter.svg"
                  alt="Twitter"
                  width="24px"
                  height="24px"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@vitsche_berlin"
                target="_blank"
                title="TikTok"
              >
                <img
                  src="@/assets/images/socials/tiktok.svg"
                  alt="TikTok"
                  width="24px"
                  height="24px"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/vitsche.berlin"
                target="_blank"
                title="Facebook"
              >
                <img
                  src="@/assets/images/socials/facebook.svg"
                  alt="Facebook"
                  width="24px"
                  height="24px"
                />
              </a>
            </li>
            <li>
              <a
                href="https://t.me/vitsche_berlin"
                target="_blank"
                title="Telegram"
              >
                <img
                  src="@/assets/images/socials/telegram.svg"
                  alt="Telegram"
                  width="24px"
                  height="24px"
                />
              </a>
            </li>
          </ul>
          <!-- <p class="label">
            <a
              href="https://drive.google.com/drive/u/0/folders/1ZQeih7IWIwV55UYbFM4EXwoynJY9pOS_"
              target="_blank"
              title=""
              >Download media kit</a
            >
          </p> -->
        </div>

        <div class="col--second">
          <ul class="nav-links">
            <li v-for="(item, index) in filteredNavigation" :key="index">
              <router-link :to="item.route" class="nav-link">{{
                $t(`${item.title}`)
              }}</router-link>
            </li>
          </ul>
        </div>

        <div class="col--third">
          <p class="label">
            {{ $t("common.initiatedBy") }}
          </p>
          <a
            href="https://vitsche.org/"
            target="_blank"
            title="Vitche"
            class="vitsche-logo"
          >
            <img
              src="@/assets/images/vitsche.svg"
              alt="Vitche.org"
              class="vitsche-img"
            />
          </a>
        </div>

        <!-- 3 col -->
        <div class="col--last">
          <p class="label">
            {{ $t("common.supportedBy") }}
          </p>
          <a
            href="https://www.auswaertiges-amt.de/en"
            target="_blank"
            title=""
            class="ffo"
          >
            <img
              src="@/assets/images/ffo.svg"
              class="ffo-logo"
              alt="Federal Foreign Office"
            />
          </a>
        </div>
      </section>

      <section class="bottom">
        <p>© 2024 Vitsche e.V., {{ $t("common.copyright") }}</p>

        <!-- TODO: udpate links -->
        <ul class="links">
          <li>
            <router-link :to="`/${currentLang}/imprint`">{{
              $t("nav.imprint")
            }}</router-link>
          </li>
          <li>
            <router-link :to="`/${currentLang}/privacy-policy`">{{
              $t("nav.privacy")
            }}</router-link>
          </li>
        </ul>
      </section>
    </div>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { getNavigation } from "@/data/nav";

export default {
  name: "AppFooter",
  setup() {
    const route = useRoute();
    const currentLang = computed(() => route.params.lang || "en");
    const navigation = computed(() => getNavigation(currentLang.value));
    const filteredNavigation = computed(() =>
      navigation.value.filter((item) => item.show && item.footer)
    );

    return {
      filteredNavigation,
      currentLang,
    };
  },
};
</script>

<style scoped>
.app-footer {
  background: var(--color-primary);
  color: var(--color-off-white);
  padding: 40px 0;
}

.ttj-logo {
  margin-bottom: 2rem;
}

.socials {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  color: white;
}

.nav-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: clamp(1rem, 0.8936rem + 0.396vw, 1.25rem);
}
@media screen and (max-width: 768px) {
  .col--second {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.nav-link {
  color: var(--color-off-white);
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.1s ease;
  border-bottom: 1px solid transparent;
}
.nav-link:hover {
  border-bottom: 1px solid currentColor;
}

.label {
  /* font-size: clamp(1.125rem, 1.0718rem + 0.198vw, 1.25rem); */
  font-size: 1.125rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

.label a {
  color: var(--color-off-white);
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}

.top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
  gap: 2rem;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.ffo {
  display: block;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--color-off-white);
}

.ffo-logo {
  width: 132px;
  height: 60px;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 2.8rem;
}

.links a {
  text-decoration: none;
  color: var(--color-off-white);
  transition: all 0.1s ease;
  border-bottom: 1px solid transparent;
}
.links a:hover {
  border-bottom: 1px solid currentColor;
}

.links li {
  display: inline;
}
</style>
